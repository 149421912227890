$(document).ready(function () {
    if($('.main-map').length){
        ymaps.ready(init);
    }
});


function init() {

  // Создание экземпляра карты.
  var myMap = new ymaps.Map('main-map__map', {
      center: [53.675580, 23.813757],
      zoom: 17
    }),
    // Контейнер для меню.
    menu = $('<ul class="main-map__list"></ul>');

  for (var i = 0, l = groups.length; i < l; i++) {
    createMenuGroup(groups[i]);
  }

  var groupName;

  function createMenuGroup(group) {
    var menuItem = $('<li></li>'),
      collection = new ymaps.GeoObjectCollection(null, {preset: group.style}),
      submenu = $('<div class="main-map__list-block"></div>');

    groupName = group.name;
    myMap.geoObjects.add(collection);
    menuItem
      .append(submenu)
      .appendTo(menu)
    for (var j = 0, m = group.items.length; j < m; j++) {
      createSubMenu(group.items[j], collection, submenu);
    }
  }

  function createSubMenu(item, collection, submenu) {
    var submenuItem = $('<a class="main-map__list-block-link" href="">' + groupName + '<br>' + item.name + '</a>'),
      placemark = new ymaps.Placemark(item.center);

    collection.add(placemark);
    submenuItem
      .appendTo(submenu)
      .bind('click', function () {
        if (!placemark.balloon.isOpen()) {
          myMap.panTo(placemark.geometry.getCoordinates(), {
            delay: 0,
            callback: function () {
              placemark.balloon.open();
            }
          });

        } else {
          placemark.balloon.close();
        }
        return false;
      });
  }

  menu.appendTo($('.main-map__address-list'));
  myMap.container.fitToViewport();

}
